import {productBoxSettings, ProductBoxSettings} from '../../../bps/components/productBox/productBoxSettings';

productBoxSettings.showArea = true;
productBoxSettings.flags.whitelist = ['free', 'savings', 'new', 'newVintage', 'award', 'bio'];
productBoxSettings.flags.whitelistTag = ['vegan'];
productBoxSettings.flags.whitelistInSet = ['free', 'new', 'award', 'bio'];
productBoxSettings.referredByHash = '';
productBoxSettings.offsetSlider = {
  mobile: 0.25,
  tablet: 0
};

export {productBoxSettings, ProductBoxSettings};
